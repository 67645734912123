<template>
  <div class="w-full p-4 shadow-lg card glass ring-2 ring-gray-300">
    <div class="flex flex-col items-center h-full text-center">
      <img
        alt="team"
        class="flex-shrink-0 object-cover object-center w-32 h-32 mb-4 rounded-full  ring-indigo-400 dark:ring-green-400 ring-2 ring-offset-2"
        :src="
          pic
            ? 'https://drive.google.com/uc?id=' + pic.substr(32, 33)
            : 'https://img.icons8.com/emoji/96/000000/bust-in-silhouette.png'
        "
      />
      <div class="w-full">
        <h2
          class="text-lg font-semibold text-gray-900 title-font dark:text-white"
        >
          {{ name }}
        </h2>
        <h3 class="mb-3 text-gray-500 dark:text-gray-50">
          {{ position }}
        </h3>

        <span class="inline-flex">
          <a
            class="dark:text-gray-50"
            v-if="facebook"
            :href="facebook"
            target="_blank"
          >
            <svg
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              class="w-5 h-5"
              viewBox="0 0 24 24"
            >
              <path
                d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"
              ></path>
            </svg>
          </a>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    pic: {
      type: String,
      default: "https://img.icons8.com/emoji/96/000000/bust-in-silhouette.png",
    },
    position: {
      type: String,
    },
    facebook: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
</style>