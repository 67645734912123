<template>
  <div class="container mx-auto text-center">
    <h2 class="my-10 text-3xl font-semibold">Tech Enthusiasts</h2>
    <div class="relative">
      <div
        class="grid justify-center grid-cols-1 gap-3 mt-10  md:gap-5 sm:grid-cols-2 md:grid-cols-3 lg:gap-10 lg:grid-cols-4"
        v-if="teammembers.length > 0"
      >
        <SingleMember
          v-for="(m, i) in teammembers"
          :key="i"
          :pic="m.pic"
          :name="m.name"
          :position="m.position"
          :facebook="m.facebook"
        />
      </div>
      <div v-else>
        <button class="btn btn-lg btn-circle loading"></button>
      </div>
    </div>
  </div>
</template>

<script>
import SingleMember from "./SingleMember.vue";
export default {
  components: { SingleMember },
  data() {
    return {
      teammembers: [],
    };
  },
  methods: {
    async getTeamMembers() {
      let res = await fetch(
        "https://script.google.com/macros/s/AKfycbxai87YWwNT7ZV-EDT2DPXbkQ-Few7CsQopC0M9QH3TyzI-tcl_xFxKU3D5Z995oWcI2g/exec?type=teammembers"
      );
      let data = await res.json();
      this.teammembers = data.members;
    },
  },
  created() {
    this.getTeamMembers();
  },
};
</script>

<style lang="scss" scoped>
.teambg {
  background-color: #dfdbe5;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h16v2h-6v6h6v8H8v-6H2v6H0V0zm4 4h2v2H4V4zm8 8h2v2h-2v-2zm-8 0h2v2H4v-2zm8-8h2v2h-2V4z' fill='%237292fc' fill-opacity='0.4' fill-rule='evenodd'/%3E%3C/svg%3E");
}
</style>